import { AnyAction } from 'redux';
import { UserAuthData } from '~/types';
import {
  AUTH_CHECK_COMPLETE,
  USER_LOGGED_IN,
  USER_LOGGED_OUT,
} from '../actions/userActions';

const INITIAL_STATE: UserAuthData = {
  userId: '',
  isLoggedIn: false,
  isAuthCheckComplete: false,
};

export default function userAuthReducer(
  state = INITIAL_STATE,
  action: AnyAction,
): UserAuthData {
  switch (action.type) {
    case USER_LOGGED_IN:
      return {
        isLoggedIn: true,
        isAuthCheckComplete: true,
        userId: action.payload.userId,
      };
    case USER_LOGGED_OUT: {
      return {
        ...INITIAL_STATE,
        isAuthCheckComplete: true,
      };
    }
    case AUTH_CHECK_COMPLETE: {
      return {
        ...INITIAL_STATE,
        isAuthCheckComplete: true,
      };
    }
    default:
      return state;
  }
}
