import { AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import cookies from 'js-cookie';

import {
  userLogout,
  validateUserCredentials,
  validateUserSession,
} from '~/services/apiService/apiService';

import { CSRF_COOKIE_NAME } from '~/constants';
import { RootState } from '../store';
import { UserData } from '~/types';
import {
  authCheckCompleteActionCreator,
  userLoggedInActionCreator,
  userLoggedOutActionCreator,
} from './userActions';
import CustomError from '~/constructors/CustomError/CustomError';

/**
 * Redux async action creator for user login in using user id password method
 * @param userId user ID
 * @param password user password
 * @returns Promise of user data
 */
export function requestUserRegularLogin(
  userId: string,
  password: string,
): ThunkAction<Promise<UserData>, RootState, void, AnyAction> {
  return (dispatch: ThunkDispatch<UserData, void, AnyAction>) =>
    validateUserCredentials(userId, password)
      .then((data) => {
        dispatch(userLoggedInActionCreator(data.user));
        cookies.set(CSRF_COOKIE_NAME, data.csrfToken);
        return data.user;
      })
      .catch((err) => Promise.reject(err));
}

/**
 * Redux async action to logout user
 * @returns Promise which either resolves with true or rejects with error
 */
export function requestUserLogout(): ThunkAction<
  Promise<boolean>,
  RootState,
  void,
  AnyAction
> {
  return (dispatch: ThunkDispatch<UserData, void, AnyAction>) =>
    userLogout()
      .then((data) => {
        dispatch(userLoggedOutActionCreator());
        cookies.remove(CSRF_COOKIE_NAME);
        return data;
      })
      .catch((err) => Promise.reject(err));
}

export function requestUserSessionValidation(): ThunkAction<
  Promise<UserData | null>,
  RootState,
  void,
  AnyAction
> {
  return (dispatch: ThunkDispatch<UserData, void, AnyAction>) =>
    validateUserSession()
      .then((data) => {
        dispatch(userLoggedInActionCreator(data));
        return data;
      })
      .catch((err: CustomError) => {
        // TODO: Move to constant
        if (err.customData.data?.errors[0]?.code === 'E005') {
          dispatch(authCheckCompleteActionCreator());
          return null;
        }
        return Promise.reject(err);
      });
}
