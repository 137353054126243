import { HttpMethods } from '~/types';

const httpMethods: { [key in HttpMethods]: HttpMethods } = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
  HEAD: 'HEAD',
};

const endpoint = {
  USER_SESSION: '/user/session',
  USER: '/user',
  EDITOR_BLOCK_CATEGORIES: '/editor/blocks/categories',
  EDITOR_BLOCK_BY_CATEGORY: '/editor/blocks',
};

const apiMap = {
  LOGIN: {
    endpoint: endpoint.USER_SESSION,
    method: httpMethods.POST,
  },
  LOGOUT: {
    endpoint: endpoint.USER_SESSION,
    method: httpMethods.DELETE,
  },
  GET_USER_DATA: {
    endpoint: endpoint.USER,
    method: httpMethods.GET,
  },
  GET_EDITOR_BLOCK_CATEGORIES: {
    endpoint: endpoint.EDITOR_BLOCK_CATEGORIES,
    method: httpMethods.GET,
  },
  GET_EDITOR_BLOCK_BY_CATEGORY: {
    endpoint: endpoint.EDITOR_BLOCK_BY_CATEGORY,
    method: httpMethods.GET,
  },
};

export default apiMap;
