import logoSmall from '~/assets/svgs/logoSmall.svg';
import './SalesflyerLoader.css';

export interface SalesflyerLoaderProps {
  className?: string;
  styleName?: string;
}

function SalesflyerLoader(props: SalesflyerLoaderProps) {
  return (
    <img
      src={logoSmall}
      alt="loading"
      styleName="image"
      className={props.className}
    />
  );
}

SalesflyerLoader.defaultProps = {
  className: '',
};

export default SalesflyerLoader;

export interface PageLoaderProps {
  fullPage?: boolean;
}

export function PageLoader(props: PageLoaderProps) {
  return (
    <div styleName={props.fullPage ? 'full-container' : 'container'}>
      <SalesflyerLoader styleName="image-center" />
    </div>
  );
}

PageLoader.defaultProps = {
  fullPage: true,
};
