import { AnyAction } from 'redux';
import { UserData } from '~/types';
import { USER_LOGGED_IN, USER_LOGGED_OUT } from '../actions/userActions';

const INITIAL_STATE: UserData = {
  userId: '',
  avatarUrl: '',
  email: '',
  firstName: '',
  middleName: '',
  lastName: '',
  lastLogin: null,
  lastLoginIp: null,
  organizationId: '',
  status: null,
};

export default function userDataReducer(
  state = INITIAL_STATE,
  action: AnyAction,
): UserData {
  switch (action.type) {
    case USER_LOGGED_IN:
      return action.payload;
    case USER_LOGGED_OUT: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
}
