import { lazy } from 'react';
import withSuspense from './HOC/withSuspense';
import retryChunkLoadWrapper from './services';

export const LoginPage = withSuspense(
  lazy(() =>
    retryChunkLoadWrapper(
      () => import(/* webpackChunkName: "Login" */ '~/pages/Login/Login'),
    ),
  ),
  true,
);

export const DashboardPage = withSuspense(
  lazy(() =>
    retryChunkLoadWrapper(
      () =>
        import(
          /* webpackChunkName: "Dashboard" */ '~/pages/Dashboard/Dashboard'
        ),
    ),
  ),
);

export const FourOFourPage = withSuspense(
  lazy(() =>
    retryChunkLoadWrapper(
      () =>
        import(
          /* webpackChunkName: "FourOFour" */ '~/pages/FourOFour/FourOFour'
        ),
    ),
  ),
);

export const SitePage = withSuspense(
  lazy(() =>
    retryChunkLoadWrapper(
      () => import(/* webpackChunkName: "Sites" */ '~/pages/Sites/Sites'),
    ),
  ),
);

export const EditorStartPage = withSuspense(
  lazy(() =>
    retryChunkLoadWrapper(
      () =>
        import(
          /* webpackChunkName: "EditorStart" */ '~/pages/EditorStart/EditorStart'
        ),
    ),
  ),
);

export const EditorPage = withSuspense(
  lazy(() =>
    retryChunkLoadWrapper(
      () => import(/* webpackChunkName: "Editor" */ '~/pages/Editor/Editor'),
    ),
  ),
);

export const CampaignPage = withSuspense(
  lazy(() =>
    retryChunkLoadWrapper(
      () =>
        import(
          /* webpackChunkName: "Campaigns" */ '~/pages/Campaigns/Campaigns'
        ),
    ),
  ),
);

export const CampaignEditorPage = withSuspense(
  lazy(() =>
    retryChunkLoadWrapper(
      () =>
        import(
          /* webpackChunkName: "CampaignEditor" */ '~/pages/CampaignEditor/CampaignEditor'
        ),
    ),
  ),
);
