import { memo } from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { NavLink, useMatch } from 'react-router-dom';
import logo from '~/assets/svgs/logo.svg';
import { RootState } from '~/redux/store';

import styles from './SideBar.css';

const links = [
  {
    name: 'Home',
    icon: 'icon-home',
    link: '/dashboard',
  },
  {
    name: 'Sites',
    icon: 'icon-browser-fill',
    link: '/sites',
  },
  {
    name: 'Campaigns',
    icon: 'icon-flag',
    link: '/campaigns',
  },
];

export const noSideBarLinks = [
  'sites/editor',
  'sites/editor/:siteId',
  'campaigns/:campaignId',
];

function SideBar() {
  const isLoggedIn = useSelector(
    (state: RootState) => state.userAuth.isLoggedIn,
  );

  let noSideBarMatch = false;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < noSideBarLinks.length; i++) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    if (useMatch(noSideBarLinks[i])) {
      noSideBarMatch = true;
      break;
    }
  }

  if (!isLoggedIn || noSideBarMatch) {
    return null;
  }

  return (
    <div styleName="sidebar">
      <img src={logo} alt="salesflyer" className="m-16 mb-32" />
      <ul styleName="menu">
        {links.map((element) => {
          return (
            <li key={element.name}>
              <NavLink
                to={element.link}
                className={({ isActive }) =>
                  classnames(
                    'width-full height-full display-flex flex-align-center flex-justify-center p-24',
                    { [styles['menu-item-active']]: isActive },
                  )
                }
              >
                <i className={element.icon} styleName="nav-icons" />
              </NavLink>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
export default memo(SideBar);
