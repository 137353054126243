import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

export function initSentry() {
  if (SENTRY_ENABLED) {
    Sentry.init({
      environment: ENV,
      normalizeDepth: 10,
      dsn: SENTRY_DSN_URL,
      integrations:
        typeof window !== 'undefined'
          ? [new Integrations.BrowserTracing()]
          : undefined,

      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 1.0,
    });
  }
}

export default Sentry;
