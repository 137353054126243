import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import SpecialRoute from './components/SpecialRoute/SpecialRoute';
import SideBar from '~/components/SideBar/SideBar';
import {
  DashboardPage,
  FourOFourPage,
  LoginPage,
  EditorStartPage,
  EditorPage,
  SitePage,
  CampaignPage,
  CampaignEditorPage,
} from './routerLoadables';

import './Router.css';

function Router() {
  return (
    <BrowserRouter>
      <SideBar />
      <div styleName="pages-container">
        <Routes>
          <Route
            path="/"
            element={
              <SpecialRoute redirectPath="/dashboard">
                <LoginPage />
              </SpecialRoute>
            }
          />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <DashboardPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/sites"
            element={
              <PrivateRoute>
                <SitePage />
              </PrivateRoute>
            }
          />
          <Route
            path="sites/editor"
            element={
              <PrivateRoute>
                <EditorStartPage />
              </PrivateRoute>
            }
          />
          <Route
            path="sites/editor/:siteId"
            element={
              <PrivateRoute>
                <EditorPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/campaigns"
            element={
              <PrivateRoute>
                <CampaignPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/campaigns/:campaignId"
            element={
              <PrivateRoute>
                <CampaignEditorPage />
              </PrivateRoute>
            }
          />
          <Route path="*" element={<FourOFourPage />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default Router;
