type CustomErrors =
  | 'ApiError'
  | 'FetchFailure'
  | 'ErrorBoundary'
  | 'InternalError';

const customErrorMap: { [key in CustomErrors]: CustomErrors } = {
  ApiError: 'ApiError',
  FetchFailure: 'FetchFailure',
  ErrorBoundary: 'ErrorBoundary',
  InternalError: 'InternalError',
};

export default customErrorMap;
