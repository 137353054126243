import { combineReducers } from 'redux';
import userData from './userDataReducer';
import userAuth from './userAuthReducer';

const rootReducer = combineReducers({
  userData,
  userAuth,
});

export default rootReducer;
