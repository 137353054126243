import { AnyAction } from 'redux';

import { UserData } from '~/types';

export const USER_LOGGED_IN = 'USER_LOGGED_IN';
export function userLoggedInActionCreator(payload: UserData): AnyAction {
  return {
    type: USER_LOGGED_IN,
    payload,
  };
}

export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';
export function userLoggedOutActionCreator(): AnyAction {
  return {
    type: USER_LOGGED_OUT,
  };
}

export const AUTH_CHECK_COMPLETE = 'AUTH_CHECK_COMPLETE';
export function authCheckCompleteActionCreator(): AnyAction {
  return {
    type: AUTH_CHECK_COMPLETE,
  };
}
