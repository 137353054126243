import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { PageLoader } from '~/components/SalesflyerLoader/SalesflyerLoader';
import { requestUserSessionValidation } from '~/redux/actions/userAsyncActions';
import { RootState, AppDispatch } from '~/redux/store';

export interface PrivateRouteProps {
  children: React.ReactElement;
}

function PrivateRoute(props: PrivateRouteProps) {
  const authStatus = useSelector((state: RootState) => ({
    isAuthCheckComplete: state.userAuth.isAuthCheckComplete,
    isLoggedIn: state.userAuth.isLoggedIn,
  }));

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (!authStatus.isAuthCheckComplete) {
      dispatch(requestUserSessionValidation()).catch(() => {
        console.log('TODO: Login failed');
      });
    }
  }, [authStatus.isAuthCheckComplete, dispatch]);

  if (authStatus.isAuthCheckComplete && !authStatus.isLoggedIn) {
    return <Navigate to="/" replace />;
  }

  if (!authStatus.isAuthCheckComplete) {
    return <PageLoader />;
  }

  return props.children;
}

export default PrivateRoute;
