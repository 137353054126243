import { Severity as severity } from '@sentry/react';
import CustomError from '~/constructors/CustomError/CustomError';
import Sentry from './sentry';

export function clearSentryGlobalUserContext() {
  Sentry.configureScope((scope) => {
    scope.clear();
  });
}

function internalLogger(
  data: string | Error | CustomError,
  level: severity,
  tags?: { [key: string]: string },
  fingerPrint?: string[],
  extraData?: { [key: string]: any },
) {
  if (typeof data === 'object') {
    Sentry.withScope((scope) => {
      scope.setLevel(level);
      if (fingerPrint?.length) {
        scope.setFingerprint(fingerPrint);
      }
      scope.setTag('errorName', data.name);
      if (data instanceof CustomError) {
        if (data.customData?.request?.requestUrl) {
          scope.setTag('API.URL', data.customData.request.requestUrl);
        }
        if (data.customData?.request?.method) {
          scope.setTag('method', data.customData.request.method);
        }
        scope.setExtra('customErrorData', data.customData || 'N/A');
      }

      Sentry.captureException(data);
    });
  } else {
    console.dir(data);
    if (tags || extraData) {
      Sentry.withScope((scope) => {
        if (fingerPrint?.length) {
          scope.setFingerprint(fingerPrint);
          scope.setTag('fileName', fingerPrint[0]);
        }
        scope.setTags(tags || {});
        scope.setExtras(extraData || {});
        Sentry.captureMessage(data as string, level);
      });
    } else {
      Sentry.captureMessage(data as string, level);
    }
  }
}

const logger = {
  logFatal: (
    data: string | Error | CustomError,
    tags?: { [key: string]: string },
    fingerPrint?: string[],
    extraData?: { [key: string]: any },
  ) => {
    console.error(
      `Type: ${severity.Fatal}, Error: `,
      data,
      `Extra data: `,
      extraData || null,
    );
    internalLogger(data, severity.Fatal, tags, fingerPrint, extraData);
  },
  logError: (
    data: string | Error | CustomError,
    tags?: { [key: string]: string },
    fingerPrint?: string[],
    extraData?: { [key: string]: any },
  ) => {
    console.error(
      `Type: ${severity.Error}, Error: `,
      data,
      `Extra data: `,
      extraData || null,
    );
    internalLogger(data, severity.Error, tags, fingerPrint, extraData);
  },
  logWarning: (
    data: string | Error | CustomError,
    tags?: { [key: string]: string },
    fingerPrint?: string[],
    extraData?: { [key: string]: any },
  ) => {
    console.warn(
      `Type: ${severity.Warning}, Error: `,
      data,
      `Extra data: `,
      extraData || null,
    );
    internalLogger(data, severity.Warning, tags, fingerPrint, extraData);
  },
  log: (
    data: string | Error | CustomError,
    tags?: { [key: string]: string },
    fingerPrint?: string[],
    extraData?: { [key: string]: any },
  ) => {
    console.log(
      `Type: ${severity.Log}, Error: `,
      data,
      `Extra data: `,
      extraData || null,
    );
    internalLogger(data, severity.Log, tags, fingerPrint, extraData);
  },
  logInfo: (
    data: string | Error | CustomError,
    tags?: { [key: string]: string },
    fingerPrint?: string[],
    extraData?: { [key: string]: any },
  ) => {
    console.info(
      `Type: ${severity.Info}, Error: `,
      data,
      `Extra data: `,
      extraData || null,
    );
    internalLogger(data, severity.Info, tags, fingerPrint, extraData);
  },
  logDebug: (
    data: string | Error | CustomError,
    tags?: { [key: string]: string },
    fingerPrint?: string[],
    extraData?: { [key: string]: any },
  ) => {
    console.debug(
      `Type: ${severity.Debug}, Error: `,
      data,
      `Extra data: `,
      extraData || null,
    );
    internalLogger(data, severity.Debug, tags, fingerPrint, extraData);
  },
  logCritical: (
    data: string | Error | CustomError,
    tags?: { [key: string]: string },
    fingerPrint?: string[],
    extraData?: { [key: string]: any },
  ) => {
    console.error(
      `Type: ${severity.Error}, Error: `,
      data,
      `Extra data: `,
      extraData || null,
    );
    internalLogger(data, severity.Critical, tags, fingerPrint, extraData);
  },
};

export default logger;
