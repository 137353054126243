import customErrorMap from '~/constants/customErrorMap';

/**
 * CustomError class extends Error, add more functionality for better error logging
 */
class CustomError extends Error {
  customData: any;

  /**
   * Constructs custom error object with error name, message and custom data
   * @param errorName Name of the error for example, TypeError, RefferenceError,
   * can be any string value
   * @param message Error message describing the error, can be any string
   * @param customData Any custom error data to be attached in the error object
   */
  constructor(
    errorName: keyof typeof customErrorMap,
    message: string,
    customData?: any,
  ) {
    super(message);
    this.name = errorName;
    this.customData = customData;

    if (typeof Error.captureStackTrace !== 'undefined') {
      Error.captureStackTrace(this, CustomError);
    }
  }
}

export default CustomError;
