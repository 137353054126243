import { StrictMode } from 'react';
import { Provider } from 'react-redux';

import '@salesflyer/design-system';

import { initSentry } from '~/services/loggingService/sentry';
import store from '~/redux/store';
import Router from './Router/Router';

import './styles/index.css';

initSentry();

function App() {
  return (
    <StrictMode>
      <Provider store={store}>
        <Router />
      </Provider>
    </StrictMode>
  );
}

export default App;
