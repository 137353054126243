import logger from '~/services/loggingService/logger';

function retryChunkLoadWrapper(
  asyncFunction: () => Promise<any>,
  chunkType = 'N/A',
  retriesLeft = 3,
  interval = 500,
): ReturnType<typeof asyncFunction> {
  return new Promise((resolve, reject) => {
    asyncFunction()
      .then(resolve)
      .catch((error) => {
        logger.logError(
          'Chunk load failed, retrying...',
          undefined,
          undefined,
          {
            retriesLeft,
            error,
          },
        );
        setTimeout(() => {
          if (retriesLeft === 1) {
            logger.logError(
              'Chunk load failed, max retry limit exceeded',
              undefined,
              undefined,
              {
                retriesLeft,
                error,
              },
            );
            reject(error);
            return;
          }
          retryChunkLoadWrapper(
            asyncFunction,
            chunkType,
            retriesLeft - 1,
            interval,
          ).then(resolve, reject);
        }, interval);
      });
  });
}

export default retryChunkLoadWrapper;
