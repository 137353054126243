import React, { Suspense } from 'react';
import { PageLoader } from '~/components/SalesflyerLoader/SalesflyerLoader';

function withSuspense(
  Component: React.ComponentType,
  fullPage: boolean = false,
) {
  return (props: any) => {
    return (
      // TODO: Add error boundary
      <Suspense fallback={<PageLoader fullPage={fullPage} />}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Component {...props} />
      </Suspense>
    );
  };
}

export default withSuspense;
