import apiMap from '~/constants/apiMap';
import initNetworkRequest from '../networkServices/networkServices';
import logger from '../loggingService/logger';
import CustomError from '~/constructors/CustomError/CustomError';
import { UserData } from '~/types';

export interface UserLoginApiResponse {
  user: UserData;
  csrfToken: string;
}
/**
 * Service for validating user credentials
 * @param userId user ID
 * @param password password
 * @returns returns API response
 */
export async function validateUserCredentials(
  userId: string,
  password: string,
): Promise<UserLoginApiResponse> {
  try {
    const response = await initNetworkRequest({
      method: apiMap.LOGIN.method,
      URL: API_URL + apiMap.LOGIN.endpoint,
      requestMasked: true,
      body: {
        email: userId,
        password,
      },
    });
    return response.data.data;
  } catch (err) {
    logger.logError(err as CustomError);
    throw err;
  }
}

/**
 * Service for logging out the user
 * @returns Promise which either resolves with true or rejects with error
 */
export async function userLogout() {
  try {
    await initNetworkRequest({
      method: apiMap.LOGOUT.method,
      URL: API_URL + apiMap.LOGOUT.endpoint,
    });
    return true;
  } catch (err) {
    logger.logError(err as CustomError);
    throw err;
  }
}

/**
 * Validate if current user session is valid
 * @returns Promise which resolves with user data in case of valid session
 */
export async function validateUserSession(): Promise<UserData> {
  try {
    const response = await initNetworkRequest({
      method: apiMap.GET_USER_DATA.method,
      URL: API_URL + apiMap.GET_USER_DATA.endpoint,
    });
    return response.data.data;
  } catch (err) {
    logger.logError(err as CustomError);
    throw err;
  }
}
